import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
        <footer className="footer fixed-bottom bg-light py-3">
        <div className="container text-center">
          <span className="text-muted">2019 - SolidLane.eu</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
