export function classNames(object: any){
    let classNames = '';

    Object.keys(object).map((key, index) => {
        if (object[key]) {
            classNames += index < Object.keys(object).length - 1 ? (key + ' ') : key;
        }
    });

    return classNames;
}