import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { classNames } from "./utilities/class-names";
// --- components
import { Header } from "./public-layout/header/header.component";
import { Menu } from "./public-layout/menu.component";
import { Routes } from "./public-layout/routes.component";
import { Footer } from "./public-layout/footer/footer.component";
// --- styles
import "./App.scss";

interface IAppProps {}

interface IAppState {
  isMenuCollapsed: boolean;
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: Readonly<IAppProps>) {
    super(props);
    this.state = { isMenuCollapsed: true };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  private toggleMenu = () => {
    this.setState({
      isMenuCollapsed: !this.state.isMenuCollapsed
    });
  };

  render() {
    const sideNavClass = classNames({
      sidenav: true,
      "sidenav--open": !this.state.isMenuCollapsed
    });

    const overlayClass = classNames({
      overlay: true,
      "overlay--active": !this.state.isMenuCollapsed
    });

    return (
      <Router>
        {/* Sidebar  */}
        {/* <div className={sideNavClass}>
            <Menu />
          </div> */}
        <React.Fragment>
          <Header onBrandButtonClicked={this.toggleMenu} />
          {/* Page content */}
          <div className="wrapper container px-0">
            <Routes />
            <Footer />

            {/* <div className={overlayClass} /> */}
          </div>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
