import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// --- components
import { Home } from "./pages/home/home.component";
import { Topics } from "./pages/topics/topics.component";

export class Routes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/" component={Home} />
        <Route path="/topics" component={Topics} />
      </React.Fragment>
    );
  }
}

export default Routes;
