import React, { Component } from "react";
import "./home.scss";
import penzes_borond_image from "../../../content/images/tickets/penzes_borond.png";

type Props = {};

type myState = {
  currentShortUrl: string;
  srcUrl: string;
};

export class Home extends Component<Props, myState> {
  constructor(Props) {
    super(Props);
    this.state = {
      currentShortUrl: "tippmix",
      srcUrl: "https://tippmix.hu"
    };
  }

  private setIframeSrc = (shortUrl: string) => {
    switch (shortUrl) {
      case "tippmix":
        this.setState({
          currentShortUrl: shortUrl,
          srcUrl: "https://tippmix.hu"
        });
        break;
      case "bet365":
        this.setState({
          currentShortUrl: shortUrl,
          srcUrl: "https://www.bet365.com/#/HO/"
        });
        break;
      case "eredmenyek":
        this.setState({
          currentShortUrl: shortUrl,
          srcUrl: "https://eredmenyek.com"
        });
        break;
      case "lovifogadas":
        this.setState({
          currentShortUrl: shortUrl,
          srcUrl: "https://lovifogadas.hu/"
        });
        break;
    }
  };

  render() {
    const opening = [
      {
        resourceKey: "monday",
        day: "Hétfő",
        hours: "13:00 - 18:00"
      },
      {
        resourceKey: "thuesday",
        day: "Kedd",
        hours: "08:00 - 18:00"
      },
      {
        resourceKey: "wednesday",
        day: "Szerda",
        hours: "08:00 - 18:00"
      },
      {
        resourceKey: "thursday",
        day: "Csütörtök",
        hours: "08:00 - 18:00"
      },
      {
        resourceKey: "friday",
        day: "Péntek",
        hours: "08:00 - 18:00"
      },
      {
        resourceKey: "saturday",
        day: "Szombat",
        hours: "07:30 - 14:00"
      },
      {
        resourceKey: "sunday",
        day: "Vasárnap",
        hours: "Zárva"
      }
    ];

    return (
      <div className="p-2 home">
        {/* <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Főoldal
            </li>
          </ol>
        </nav> */}
        <div>
          <h4 id="news" className="section-header">
            Hírek
          </h4>
          <div className="mb-4 d-flex justify-content-center">
            <h5>Kedves fogadók!</h5>
          </div>
          <div className="mb-5 text-center">
            Üzletünk 2020. május 11-től (hétfő) újra a megszokott, vírus előtti
            nyitvatartásban lesz nyitva.
          </div>
          <div className="mb-5 d-flex justify-content-center">
            <table>
              <tbody>
                {opening.map((x, index) => (
                  <tr key={x.resourceKey}>
                    <td style={{ width: "100px" }}>{x.day}</td>
                    <td className="text-center">{x.hours}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h4 id="sales" className="section-header">
            Akció
          </h4>
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h5>Új sorsjeggyel bővült kínálatunk!</h5>

              <div className="d-flex justify-content-center mb-4 mt-4">
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <img
                        src={penzes_borond_image}
                        alt="Pénzes bőrönd"
                        className="img-fluid pointered"
                        
                      />
                    </div>
                    <div className="flip-box-back">
                      <p className="font-weight-bold">Pénzes bőrönd</p>
                      <div className="d-flex justify-content-center mb-2">
                        <table>
                          <tbody>
                            <tr>
                              <td
                                className="text-left"
                                style={{ width: "200px" }}
                              >
                                Ár:
                              </td>
                              <td className="text-right">1 000 Ft</td>
                            </tr>
                            <tr>
                              <td
                                className="text-left"
                                style={{ width: "200px" }}
                              >
                                Főnyeremény:
                              </td>
                              <td className="text-right">50 000 000 Ft</td>
                            </tr>
                            <tr>
                              <td
                                className="text-left"
                                style={{ width: "200px" }}
                              >
                                Megjelenés:
                              </td>
                              <td>2020. május 8.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="https://bet.szerencsejatek.hu/sorsjegyek/penzes-borond"
                target="_blank"
                className="btn btn-primary btn-sm stretched-link mb-2"
              >
                További információ
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private navigateToSzrt = () => {
    window.open(
      "https://bet.szerencsejatek.hu/sorsjegyek/penzes-borond",
      "_blank"
    );
  };
}

export default Home;
