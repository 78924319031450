import React, { Component } from "react";
import "./header.scss";
import logo from "../../content/images/clover.svg"
import { Menu } from "../menu.component";

export interface IHeaderProps{
  onBrandButtonClicked: Function;
}

export class Header extends Component<IHeaderProps, any> {
  constructor(props: Readonly<IHeaderProps>) {
    super(props);
  }

  render() {
    return (
      <nav className="navbar sticky-top navbar-light bg-light">
        <a className="navbar-brand" href="#" onClick={() => this.props.onBrandButtonClicked()}>
          <img
            src={logo}
            width="30"
            height="30"
            className="brand-logo d-inline-block align-top mr-2"
            alt=""
          />
          Fortuna Miniszalon
        </a>
      </nav>
    );
  }
}

export default Header;
